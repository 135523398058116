/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, finalize } from 'rxjs/operators';
import { LoginResponse } from '../models/login/login-response.model';
import { ApiService } from '../services/api.service';
import { AppService } from '../services/app.service';
import { LogoutService } from '../services/logout.service';
import { SessionService } from '../services/session.service';
import { REQUEST_AUTH_HEADER_KEY } from '../shared/app.const';

@Injectable()
export class RefreshSessionInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  private refreshSessionSubject: Subject<string> = null;

  constructor(
    private sessionSvc: SessionService,
    private apiSvc: ApiService,
    private logoutSvc: LogoutService,
    private appSvc: AppService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((reqError: HttpErrorResponse) => {
        if (this.noNeedToRefreshSession(req, reqError)) {
          return throwError(reqError);
        }

        if (this.requestNeedsRefreshSession(req, reqError)) {
          return this.refreshSessionSubject.pipe(
            switchMap((accessToken: string) => {
              if (accessToken) {
                return next.handle(this.updateAuthHeader(req, accessToken));
              }
              this.appSvc.goToLogin();
              return throwError(reqError);
            }),
          );
        }

        if (this.conditionForRefreshToken(reqError) && !this.isLogoutReq(req.url)) {
          if (!this.sessionSvc.refreshToken) {
            this.appSvc.goToLogin();
            return throwError(reqError);
          }

          this.isRefreshing = true;
          this.initRefreshSessionSubject();

          return this.refreshSession().pipe(
            switchMap((response: LoginResponse) => {
              this.setNewSession(response);
              this.completeRefreshSessionSubject(response.accessToken);
              return next.handle(this.updateAuthHeader(req, response.accessToken));
            }),
            catchError((refreshSessionError: HttpErrorResponse) => {
              this.completeRefreshSessionSubject(null);
              if (refreshSessionError.status === 401) {
                this.appSvc.goToLogin();
              }
              return throwError(reqError);
            }),
            finalize(() => {
              this.isRefreshing = false;
            }),
          );
        }

        return throwError(reqError);
      }),
    );
  }

  private conditionForRefreshToken(err: HttpErrorResponse) {
    const apiError = this.apiSvc.errorHandler(err);
    return (
      apiError.status === 401 &&
      (apiError.error === 'invalid_token' ||
        apiError.errorType === 'InsufficientAuthenticationException')
    );
  }

  private updateAuthHeader(req: HttpRequest<any>, token: string) {
    return req.clone({ headers: req.headers.set(REQUEST_AUTH_HEADER_KEY, `Bearer ${token}`) });
  }

  private isLogoutReq(reqUrl: string) {
    return reqUrl.includes(this.logoutSvc.BASE_URL);
  }

  private refreshSession(): Observable<LoginResponse> {
    const url = 'users/token';
    return this.apiSvc.get(
      url,
      {
        params: {
          token: `${this.sessionSvc.refreshToken}`,
        },
      },
      false,
    );
  }

  private isRefreshSessionReq(reqUrl: string) {
    return reqUrl.includes('users/token');
  }

  private noNeedToRefreshSession(req: HttpRequest<any>, reqError: HttpErrorResponse) {
    return (
      !this.conditionForRefreshToken(reqError) ||
      this.isLogoutReq(req.url) ||
      this.isRefreshSessionReq(req.url)
    );
  }

  private requestNeedsRefreshSession(req: HttpRequest<any>, reqError: HttpErrorResponse) {
    return (
      this.conditionForRefreshToken(reqError) && this.isRefreshing && !this.isLogoutReq(req.url)
    );
  }

  private setNewSession(response: LoginResponse) {
    this.sessionSvc.token = response.accessToken;
    this.sessionSvc.tokenExpiresIn = response.accessTokenValidity;
  }

  private initRefreshSessionSubject() {
    this.refreshSessionSubject = new Subject<string>();
  }

  private completeRefreshSessionSubject(accessToken: string | null) {
    if (this.refreshSessionSubject) {
      this.refreshSessionSubject.next(accessToken);
      this.refreshSessionSubject.complete();
      this.refreshSessionSubject = null;
    }
  }
}
