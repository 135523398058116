/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { RootModuleType } from 'src/app/models/root-module.model';
import { AppService } from 'src/app/services/app.service';
import { MenuItem, MenuService } from '../services/menu.service';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'cm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() items: MenuItem[];

  @Input() mode: 'side' | 'over' | 'push';

  @Input() isPrimary = false;

  private menuEls: NodeListOf<HTMLElement>;

  constructor(
    public menuService: MenuService,
    private appSvc: AppService,
    private hostEl: ElementRef,
    private projectService: ProjectService,
  ) {
    this.items = this.items || menuService.items;
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    this.initMenuElements();

    if (!this.menuEls || !this.menuEls.length) {
      return;
    }
  }

  hasAccess(item: MenuItem) {
    const accessProjects = item.accessRules;

    if (!this.checkRootModule(item.rootModule)){
      return false;
    }

    if (item.accessRules){
      return this.projectService.validAccessRuleByProject(accessProjects)
    }

    return true;
  }

  private checkRootModule(rootModule: RootModuleType) {
    return this.appSvc.rootModule[rootModule];
  }

  private initMenuElements() {
    const hostEl: HTMLElement = this.hostEl?.nativeElement;

    if (!hostEl) {
      return;
    }

    this.menuEls = hostEl.querySelectorAll('cm-menu-item:not(.sub-menu-level-2)');
  }
}
