/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { PopupService } from 'form-controls';
import { NotificationsService } from 'angular2-notifications';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorHandlerService {
  projectName = environment.project.name;

  constructor(private popupSvc: PopupService, private notificationSvc: NotificationsService) {}

  handleWithPopup(err: any): void {
    this.popupSvc.openWarningDialog(this.getErrorMessage(err));
  }

  handleBlobErrorWithPopup(err: any) {
    if (err.error instanceof Blob) {
      (err.error as Blob).text().then((str: string) => {
        try {
          const eObj = JSON.parse(str);
          eObj.status = err.status;
          this.handleWithPopup(eObj);
        } catch (e: any) {
          console.error(e);
        }
      });
    } else {
      this.handleWithPopup(err);
    }
  }

  handleWithNotification(err: any): void {
    if (this.getErrorMessage(err)) {
      this.notificationSvc.error('Error', this.getErrorMessage(err));
    }
  }

  private getErrorMessage(error): string {
    if ([400, 403].includes(error.status)) {
      if (error.message) {
        return error.message;
      }

      if (error.error) {
        return error.error;
      }

      return `Something went wrong. Please contact ${this.projectName} Support.`;
    }

    if (
      [401].includes(error.status) &&
      (error.error === 'invalid_token' || error.errorType === 'InsufficientAuthenticationException')
    ) {
      return '';
    }

    return `Something went wrong. Please contact ${this.projectName} Support.`;
  }
}
