/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, HostListener, Input } from '@angular/core';
import { Location } from '@angular/common';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { UserIs } from 'src/app/models/user-is.model';
import { MatDialog } from '@angular/material/dialog';
import { ReceiveNewActionItemsService } from 'src/app/services/receive-new-action-items.service';
import { CMS_BASE_PATH } from 'src/app/shared/app.const';
import { environment } from 'src/environments/environment';
import { ProfileService } from 'src/app/services/profile.service';
import { cloneDeep } from 'lodash';
import { MenuCallback } from 'src/app/models/menu.model';
import { ErrorHandlerService } from 'src/app/services/general/error-handler.service';
import { MenuItem, MenuService } from '../../services/menu.service';
import { ScreenService } from '../../services/screen.service';

@Component({
  selector: 'cm-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  @Input() item: MenuItem;

  @Input() adaptiveSubmenu = false;

  isSubmenuVisible = false;

  projectName = environment.project.name;

  constructor(
    public menuService: MenuService,
    private screenService: ScreenService,
    private location: Location,
    private authorizationSvc: AuthorizationService,
    private dialog: MatDialog,
    private receiveNewActionItemsSvc: ReceiveNewActionItemsService,
    private profileService: ProfileService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent) {
    const menuEl = event.currentTarget as HTMLElement;

    const subMenuEl: HTMLElement = menuEl.querySelector(':scope > cm-sub-menu');

    if (!subMenuEl) {
      return;
    }

    const rect = subMenuEl.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const submenuHeight = subMenuEl.offsetHeight;
    const submenuWidth = subMenuEl.offsetWidth;
    const menuItemRect = menuEl.getBoundingClientRect();
    const menuItemHeight = menuItemRect.height;

    let calculatedTop = menuItemRect.bottom - menuItemHeight;

    if (calculatedTop + submenuHeight > viewportHeight) {
      calculatedTop = menuItemRect.top - submenuHeight + menuItemHeight;

      if (calculatedTop < 0) {
        calculatedTop = 0;
      }
    }

    let calculatedLeft = menuItemRect.right;

    if (calculatedLeft + submenuWidth > viewportWidth) {
      calculatedLeft = menuItemRect.left - submenuWidth;

      if (calculatedLeft < 0) {
        calculatedLeft = 0;
      }
    }

    subMenuEl.style.top = `${calculatedTop}px`;
    subMenuEl.style.left = `${calculatedLeft}px`;

    this.isSubmenuVisible = true;
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event) {
    this.isSubmenuVisible = false;
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    // this.isSubmenuVisible = false;
  }

  get newActionItemsCount() {
    return this.receiveNewActionItemsSvc.newActionItemsCount;
  }

  get showNewAiCount() {
    return this.newActionItemsCount > 0 && this.item.route === `${CMS_BASE_PATH}`;
  }

  onMenuItemClick() {
    if (!this.screenService.isLarge()) {
      this.menuService.isMinimized = true;
    }
    if (this.item.modal) {
      this.dialog.open(this.item.modal.component, this.item.modal.config);
    }
    if (this.item.callback === MenuCallback.OCRG_LINK) {
      const { email, firstName, lastName } = cloneDeep(this.profileService.user);
      this.menuService.getOCRGLink({ email, firstName, lastName }).subscribe({
        next: (response: any) => {
          window.open(response.url, '_blank');
        },
        error: (err: any) => {
          this.errorHandlerService.handleBlobErrorWithPopup(err);
        },
      });
    }
  }

  checkActiveLink(): boolean {
    if (this.item.activeBy) {
      const path = this.location.path().split('?')[0];
      return !!this.item.activeBy.find((by) => {
        const segs = path.split(by.path);

        if (segs.length === 2) {
          const afterPath = segs[1];

          if (by.doesNotInclude && afterPath.includes(by.doesNotInclude)) {
            return false;
          }

          if (!by.afterPaths.length) {
            return true;
          }

          for (const ap of by.afterPaths) {
            if ((ap && afterPath.includes(ap)) || (!ap && !afterPath)) {
              return true;
            }
          }
        }

        return false;
      });
    }

    if (this.item.activeLinks && this.item.activeLinks.length > 0) {
      const path = this.location.path().split('?')[0];
      const subPaths = path.split('/');

      return !!this.item.activeLinks?.find((link) => subPaths?.includes(link));
    }
  }

  changeTitleForOtherUsers(title: string) {
    if (title === 'dashboard') {
      if (this.authorizationSvc.userIs([UserIs.INTERNAL.ADMIN])) {
        title = 'Admin dashboard';
      } else if (this.authorizationSvc.userIs([UserIs.INTERNAL.ANALYST])) {
        title = 'Analyst dashboard';
      } else {
        title = `CMS Dashboard`;
      }
    }

    return title;
  }
}
