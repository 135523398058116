/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpOptions } from '../models/http-options.model';
import { REQUEST_AUTH_HEADER_KEY } from '../shared/app.const';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly BASE_URL = '/api/';

  constructor(private httpClient: HttpClient, private sessionSvc: SessionService) {}

  get<T>(url: string, options: HttpOptions = {}, withAuth = true): Observable<T> {
    this.setCustomHttpOptions(options, withAuth);

    return this.httpClient.get<T>(this.url(url), options as any).pipe(
      catchError((err: any) => {
        console.error(err);
        throw this.errorHandler(err);
      }),
    ) as Observable<T>;
  }

  post<T>(url: string, body: any, options: HttpOptions = {}, withAuth = true): Observable<T> {
    this.setCustomHttpOptions(options, withAuth);

    return this.httpClient.post<T>(this.url(url), body, options as any).pipe(
      catchError((err: any) => {
        console.error(err);
        throw this.errorHandler(err);
      }),
    ) as Observable<T>;
  }

  put<T>(url: string, body: any, options: HttpOptions = {}, withAuth = true): Observable<T> {
    this.setCustomHttpOptions(options, withAuth);

    return this.httpClient.put<T>(this.url(url), body, options as any).pipe(
      catchError((err: any) => {
        console.error(err);
        throw this.errorHandler(err);
      }),
    ) as Observable<T>;
  }

  delete<T>(url: string, options: HttpOptions = {}, withAuth = true): Observable<T> {
    this.setCustomHttpOptions(options, withAuth);

    return this.httpClient.delete<T>(this.url(url), options as any).pipe(
      catchError((err: any) => {
        console.error(err);
        throw this.errorHandler(err);
      }),
    ) as Observable<T>;
  }

  errorHandler(httpError: HttpErrorResponse): any {
    let apiError = httpError.error;

    if (typeof apiError === 'string') {
      if (apiError.includes('invalid_token')) {
        return { error: 'invalid_token', status: 401 };
      }

      try {
        apiError = JSON.parse(apiError);
      } catch (e) {
        return httpError;
      }
    }

    if (apiError instanceof Blob && httpError.status === 401) {
      return { error: 'invalid_token', status: 401 };
    }

    if (apiError instanceof Blob) {
      return { error: apiError, status: httpError.status };
    }

    return { ...apiError, status: httpError.status };
  }

  private setCustomHttpOptions(options: HttpOptions, withAuth: boolean) {
    this.setHeadersOption(options, withAuth);
    this.setObserveOption(options);
    this.setResponseTypeOption(options);
  }

  private setHeadersOption(options: HttpOptions, withAuth: boolean) {
    if (!('headers' in options)) {
      options.headers = {};
    }

    options.headers['UserDateTime'] = moment().format();

    if (withAuth) {
      options.headers[REQUEST_AUTH_HEADER_KEY] = `Bearer ${this.sessionSvc.token}`;
    }
  }

  private setObserveOption(options: HttpOptions) {
    if ('observe' in options) {
      return;
    }

    options.observe = 'body';
  }

  private setResponseTypeOption(options: HttpOptions) {
    if ('responseType' in options) {
      return;
    }

    options.responseType = 'json';
  }

  private url(url: string): string {
    return this.BASE_URL + url;
  }
}
