/* eslint-disable class-methods-use-this */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { REQUEST_AUTH_HEADER_KEY } from '../shared/app.const';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  private readonly REQUIRED_AUTH_HEADER_LENGTH = 7;

  constructor(private appSvc: AppService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      req.headers.has(REQUEST_AUTH_HEADER_KEY) &&
      req.headers.get(REQUEST_AUTH_HEADER_KEY).length < this.REQUIRED_AUTH_HEADER_LENGTH
    ) {
      this.appSvc.goToLogin();
      return EMPTY;
    }

    return next.handle(req);
  }
}
