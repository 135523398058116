import { Injectable } from '@angular/core';
import { getCookie } from '../shared/utils/jsUtils';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private readonly LS_TOKEN_KEY: string;

  private readonly LS_TOKEN_EXPIRES_KEY: string;

  private readonly COOKIE_REFRESH_TOKEN_KEY: string;

  constructor(private storageSvc: StorageService) {
    this.LS_TOKEN_KEY = 'token';
    this.LS_TOKEN_EXPIRES_KEY = 'token.accessTokenValidity';
    this.COOKIE_REFRESH_TOKEN_KEY = 'refresh_token';
  }

  get token(): string {
    const token = this.storageSvc.get(this.LS_TOKEN_KEY);

    if (!this.isTokenValid(token)) {
      return null;
    }

    return token;
  }

  set token(token: string) {
    if (!this.isTokenValid(token)) {
      console.error('SET TOKEN: Token is invalid!');
      return;
    }

    this.storageSvc.set(this.LS_TOKEN_KEY, token);
  }

  get tokenExpiresIn() {
    const tokenExpiresIn = Number(this.storageSvc.get(this.LS_TOKEN_EXPIRES_KEY));

    if (tokenExpiresIn > 0) {
      return tokenExpiresIn;
    }

    return null;
  }

  set tokenExpiresIn(seconds: number) {
    if (!seconds) {
      console.error('SET TOKEN EXPIRES IN: Expires In is invalid!');
      return;
    }

    this.storageSvc.set(this.LS_TOKEN_EXPIRES_KEY, seconds);
  }

  get refreshToken(): string {
    const refreshToken = getCookie(this.COOKIE_REFRESH_TOKEN_KEY);
    if (!this.isTokenValid(refreshToken)) {
      return null;
    }

    return refreshToken;
  }

  destroySession() {
    this.storageSvc.removeItem(this.LS_TOKEN_KEY);
    this.storageSvc.removeItem(this.LS_TOKEN_EXPIRES_KEY);
  }

  private isTokenValid(token: string): boolean {
    return !!(typeof token === 'string' && token.length);
  }
}
